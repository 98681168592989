<template>
  <TsCard
    no-border
    :data-testid="`${props.dataTestid}-card`"
    :header-class="
      twMerge('flex-1', product_layout === 'desktop-list' && 'pb-0')
    "
    body-class="py-0 flex-1"
    :footer-class="
      twMerge(
        'pt-0 mt-auto flex flex-col',
        product_layout === 'desktop-list' &&
          'flex-1 border-l border-grey mt-0 pl-4'
      )
    "
    :id="props.id || `product-${product?.code}-card-` + random_id"
    :append-class="
      twMerge(
        'flex flex-col h-full w-full drop-shadow-[1px_5px_12px_0px_#00152A0F] px-2 py-4 text-left bg-white relative',
        (product_layout === 'mobile-list' ||
          product_layout === 'desktop-list') &&
          'px-4',
        product_layout === 'desktop-grid' && 'px-3',
        product_layout === 'desktop-list' && 'flex-row gap-4',
        !product_layout && 'py-3'
      )
    "
  >
    <template #header>
      <TsMedia append-class="items-center mb-2">
        <TsMediaStart>
          <div v-if="topRightTags?.length">
            <TsTag
              v-for="(item, index) in topRightTags"
              :key="item"
              :data-testid="`${props.dataTestid}-tag-${index}`"
              :label="item"
              :append-class="index !== topRightTags.length - 1 ? 'mr-1' : ''"
              size="xs"
              rounded
              variant="info"
            />
          </div>
        </TsMediaStart>
        <TsMediaEnd>
          <TsButton
            v-if="!isSaved"
            :icon="
              props.product?.isSaved ? 'solar:heart-bold' : 'solar:heart-linear'
            "
            :icon-class="
              props.product?.isSaved ? 'size-6 text-red-600' : 'size-6'
            "
            variant="secondary"
            size="xs"
            rounded
            append-class="hover:bg-natural-light-grey p-1"
            @click="handleSavedListClick()"
          />
          <!-- Please ensure that you pass the product code as props when you are emitting removeProduct. I was lazy to add type safety to the emit lol -->
          <TsButton
            v-if="isSaved"
            icon="charm:cross"
            icon-class="size-4"
            variant="secondary"
            :append-class="
              product_layout === 'desktop-list' ? 'absolute top-3 right-4' : ''
            "
            size="xs"
            @click="emit('removeProduct', props.product?.code)"
            rounded
          />
        </TsMediaEnd>
      </TsMedia>

      <div class="relative">
        <NuxtImg
          v-if="isFreeStarterKitTag"
          class="absolute top-0 left-0"
          src="/images/product-free-starter-kit.png"
          width="41"
        />

        <div class="flex justify-center">
          <NuxtLink :to="`/product/${product?.slug}-${product?.code}`">
            <NuxtImg
              :src="product?.image"
              :width="
                product_layout === 'desktop-grid'
                  ? '204'
                  : product_layout === 'desktop-list'
                  ? '212'
                  : product_layout === 'mobile-list'
                  ? '278'
                  : product_layout === 'mobile-grid'
                  ? '178'
                  : props.productImageWidth
              "
              :height="
                product_layout === 'desktop-grid'
                  ? '204'
                  : product_layout === 'desktop-list'
                  ? '212'
                  : product_layout === 'mobile-list'
                  ? '278'
                  : product_layout === 'mobile-grid'
                  ? '178'
                  : props.productImageHeight
              "
            />
          </NuxtLink>
        </div>
      </div>

      <div class="flex items-center justify-between gap-2">
        <TsButton
          v-if="product?.variations?.length"
          data-testid="product-similar-variants"
          icon="solar:copy-line-duotone"
          label="Variants"
          variant="secondary"
          append-class="gap-1 py-1.5 px-2 hover:bg-natural-light-grey"
          size="xs"
          rounded
          @click="handleVariantsClick"
        />

        <TsTypography append-class="ms-auto py-2" size="xs">
          <strong>ID: </strong>
          {{ product?.code }}
        </TsTypography>
      </div>
    </template>

    <template #content>
      <!-- Find appropriate condition for limitedTimeOffer display -->
      <TsTag
        v-if="limitedTimeOffer.expiresIn"
        variant="danger"
        append-class="mb-3"
        size="xs"
        rounded
      >
        <Icon name="oi:timer" size="12" />
        {{ limitedTimeOffer.text }}
      </TsTag>

      <NuxtLink :to="`/product/${product?.slug}-${product?.code}`">
        <TsTypography
          append-class="line-clamp-2 text-natural-black mb-2 hover:underline"
        >
          <strong>{{ product?.brand }}</strong> {{ product?.full_name }}
        </TsTypography>
      </NuxtLink>

      <TsTypography
        v-if="!props.hideSpecifications && !(product_layout === 'mobile-grid')"
        as="span"
        :append-class="
          twMerge(
            'line-clamp-1 mb-3',
            product_layout === 'desktop-grid' &&
              'mb-2 line-clamp-2 leading-loose'
          )
        "
        size="sm"
        weight="normal"
      >
        <span
          v-for="techSpec in props.product?.technical_spec_attributes?.filter(
            (attr) =>
              attr.key !== 'EAN' &&
              attr.key !== 'Brand' &&
              attr.key !== 'Manufacturer ID'
          )"
          :key="techSpec.id"
        >
          {{ techSpec.key }} : {{ techSpec.value }} |
        </span>
      </TsTypography>

      <TsRatings
        v-if="isDesktop && product_layout === 'desktop-list' && productReviews"
        append-class="mb-4"
        :data-testid="
          props.dataTestid
            ? props.dataTestid + '-product-card-rating-stars'
            : '-product-card-rating-stars'
        "
        v-model="productRatings"
        :stars="5"
        :label="`(${productReviews})`"
        size="sm"
      />

      <ul
        v-if="isDesktop && product_layout === 'desktop-list'"
        class="list-disc list-inside line-clamp-3"
      >
        <TsTypography
          as="li"
          append-class="text-natural-grey mb-0"
          weight="normal"
          v-for="techSpec in props.product?.technical_spec_attributes?.filter(
            (attr) =>
              attr.key !== 'EAN' &&
              attr.key !== 'Brand' &&
              attr.key !== 'Manufacturer ID'
          )"
          :key="techSpec.id"
        >
          {{ techSpec.key }} : {{ techSpec.value }} |
        </TsTypography>
      </ul>
      <TsMedia
        :append-class="
          twMerge(
            'items-center mb-3 gap-2',
            product_layout === 'mobile-grid' && 'flex-col-reverse items-start',
            props.priceAndRatingsContainerClass
          )
        "
      >
      <TsMediaContent>
        <TsTypography
          variant="sm"
          v-if="product?.prices?.was_price_raw !== 0 && ((product?.prices?.was_price_raw.toFixed(2)) !== product?.prices?.formatted.gross)"
          append-class="line-through leading-tight"
        >
          {{ product?.prices?.was_price_formatted }}
        </TsTypography>
          <TsTypography
            variant="h2"
            weight="bold"
            append-class="text-idle-black leading-normal mb-0 leading-tight"
          >
          {{ !rootStore.isIncVat ? product?.prices?.formatted.net : product?.prices?.formatted.gross }}
            <TsTypography
              v-if="product?.prices?.formatted.per_unit"
              as="span"
              variant="xs"
              append-class="text-natural-silver-grey leading-tight"
            >
              {{ product?.prices?.formatted.per_unit }}
            </TsTypography>
          </TsTypography>
          <TsTypography as="span" variant="xs" append-class="leading-tight">
            {{ rootStore.isIncVat ? 'Excl. VAT' : 'Inc. VAT' }}
            {{ rootStore.isIncVat ? product?.prices?.formatted.net : product?.prices?.formatted.gross }}
          </TsTypography>
        </TsMediaContent>
        <TsMediaEnd
          v-if="!(product_layout === 'desktop-list')"
          :append-class="
            twMerge(
              product_layout === 'mobile-grid' && 'ms-0',
              props.ratingsContainerClass
            )
          "
        >
          <TsRatings
            v-if="productReviews"
            :data-testid="
              props.dataTestid
                ? props.dataTestid + '-product-card-rating-stars'
                : '-product-card-rating-stars'
            "
            v-model="productRatings"
            :stars="5"
            :label="`(${productReviews})`"
            :read-only="true"
          />
        </TsMediaEnd>
      </TsMedia>
    </template>

    <template #footer>
      

      <div
        v-if="
          props.product?.stockDetails?.collection! > 0 ||
          props.product?.stockDetails?.delivery! > 0
        "
        :class="
          twMerge(
            'flex items-center gap-2 mt-auto',
            (product_layout === 'mobile-grid' ||
              product_layout === 'desktop-list') &&
              'flex-col-reverse',
            !product_layout && 'mb-0',
            product_layout === 'desktop-list' && 'mt-0'
          )
        "
      >
        <TsButton
          v-if="!props.product?.direct_ship && props.product?.click_and_collect"
          icon="mynaui:store"
          :label="isNextDayCollectionCTA ? 'Next Day' : 'Collection'"
          :disabled="disableCollectionCTA"
          :data-testid="
            (isNextDayCollectionCTA ? `next-day-collection-` : `collection-`) +
            `cta-${props.product?.code}`
          "
          size="sm"
          block
          append-class="whitespace-nowrap px-2.5 gap-1.5"
          @click="handleCollectionInit"
        />

        <TsButton
          icon="mdi:truck-delivery-outline"
          label="Delivery"
          :disabled="disableDeliveryCTA"
          :data-testid="
            (props.product?.direct_ship ? `directship-` : `delivery-`) +
            `cta-${props.product?.code}`
          "
          size="sm"
          block
          append-class="whitespace-nowrap px-2.5 gap-1.5"
          @click="handleDeliveryInit"
        />
      </div>
      <TsRow append-class="m-0" v-else>
        <TsButton
          icon="mdi:bell-outline"
          label="Notify me"
          :data-testid="`out-of-stock-notification-cta-${props.product?.code}`"
          size="sm"
          @click="trolleyStore.subscribe_notification_modal_visible = true"
          block
        />
      </TsRow>
      <div v-if="compareOption" :class="compareOption === 'Yes' ? 'flex justify-center mt-4' : 'mb-6'">
        <TsCheckbox
          id=""
          data-testid=""
          label="Compare"
          v-model="isCompareChecked"
          @change="handleCompareChange"
        />
      </div>
    </template>
  </TsCard>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import { ref, onMounted } from "vue";
import { useRandomUUID } from "../../composables/useRandomUUID";
import { data } from "../../json/product.json";
import type { Product } from "~/types/ecom/product/product.type";
import { toast } from "vue3-toastify";

const savelistStore = useSavelistStore();

type Props = {
  product?: Partial<Product> | null;
  appendClass?: string;
  limitedTimeOffer?: {
    text: string;
    expiresIn?: string | Date;
  };
  addedToSavedList?: boolean;

  // Product props
  dataTestid?: string;
  id?: string;
  layout?: "grid" | "list";
  tags?: string[];
  hideBuyNow?: boolean;
  hideSpecifications?: boolean;
  buttonOutlined?: boolean;
  priceAndRatingsContainerClass?: string;
  ratingsContainerClass?: string;
  productImageWidth?: string;
  productImageHeight?: string;
  isSaved?: boolean;
  isCompare?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  product: () => data,
  limitedTimeOffer: () => ({
    text: "Limited time deal",
  }),
  appendClass: "",
  priceAndRatingsContainerClass: "",
  ratingsContainerClass: "",
  tags: () => ["New", "20% off"],
});

const { isMobile, isDesktop } = useDevice();

const productAttributeStore = useProductAttributeStore();

const trolleyStore = useTrolleyStore();
const stockStore = useStockStore();
const compareStore = useCompareStore();
const rootStore = useRootStore();
const isCompareChecked = ref(props.isCompare || false);

const isNextDayCollectionCTA = computed(() =>
  stockStore.isEligibleForNextDayCollection(props.product as Product)
);

const disableDeliveryCTA = computed(
  () => !props.product?.stockDetails?.delivery
);

const disableCollectionCTA = computed(() => {
  if (!useBranchStore().is_branch_set || isNextDayCollectionCTA.value)
    return false;
  return !props.product?.stockDetails?.collection;
});

// trolley action
async function handleCollectionInit() {
  trolleyStore.currentTrolleyAction = "Collection";

  await trolleyStore.listenToBranchSet();
  trolleyStore.preview_overlay_collection_visible = true;
  const [productWithStock] = await stockStore.getStockForProducts([props.product?.code as string]);
  trolleyStore.previewed_product_v2 = productWithStock;
  await trolleyStore.createTrolleyV2();

  if (
    stockStore.isEligibleForNextDayCollection(trolleyStore.previewed_product_v2)
  )
    await trolleyStore.addToTrolleyForNextDayCollection(
      trolleyStore.previewed_product_v2.code,
      1
    );
  else
    await trolleyStore.addToTrolleyForCollection(
      trolleyStore.previewed_product_v2.code,
      1
    );

  trolleyStore.currentTrolleyAction = "";
}

async function handleVariantsClick() {
  trolleyStore.product_variants_modal_visible = true;
  trolleyStore.previewed_product_v2 = props.product as Product;
  await trolleyStore.getProductVariants(props.product?.variations as string[]);
}

// delivery action
async function handleDeliveryInit() {
  trolleyStore.currentTrolleyAction = "Delivery";

  trolleyStore.previewed_product_v2 = props.product as Product;
  trolleyStore.preview_overlay_delivery_visible = true;
  await trolleyStore.createTrolleyV2();

  if (trolleyStore.previewed_product_v2.direct_ship)
    await trolleyStore.addToTrolleyForDirectship(
      trolleyStore.previewed_product_v2.code,
      1
    );
  else
    await trolleyStore.addToTrolleyForDelivery(
      trolleyStore.previewed_product_v2.code,
      1
    );

  trolleyStore.currentTrolleyAction = "";
}

function convertToNearestWholeNumber(num: number) {
  const firstDecimalPlace = Math.floor(num * 10) % 10;
  if (firstDecimalPlace < 5) {
    return Math.floor(num);
  } else {
    return Math.ceil(num);
  }
}

const handleSavedListClick = () => {
  const authStore = useAuthStore();
  if (!authStore.is_authenticated) {
    navigateTo("/auth");
  } else {
    emit("toggleSavedList");
    savelistStore.modal_savelist_from_visible = true;
    if (!savelistStore.fetching_in_progress) {
      savelistStore.getUserSaveLists();
    }
    savelistStore.selectedSavelist = [];
    if (props.product !== null) {
      savelistStore.current_active_product = props.product as Product;
    }
    savelistStore.populateSavedList();
  }
};

const product_layout = computed(() => {
  if (isMobile && props.layout === "grid") {
    return `mobile-${props.layout}`;
  } else if (isMobile && props.layout === "list") {
    return `mobile-${props.layout}`;
  } else if (isDesktop && props.layout === "grid") {
    return `desktop-${props.layout}`;
  } else if (isDesktop && props.layout === "list") {
    return `desktop-${props.layout}`;
  }
  return "";
});

const emit = defineEmits<{
  toggleSavedList: any;
  removeProduct: any;
}>();

const topRightTags = productAttributeStore.getTopRightTagValues(
  props.product?.other_attributes!
);

const productRatings = ref(
  convertToNearestWholeNumber(
    Number(
      productAttributeStore.getProductRating(props.product?.other_attributes!)
    )
  )
);

const productReviews = ref(
  productAttributeStore.getNumberOfReviews(props.product?.other_attributes!)
);

const isFreeStarterKitTag = computed(() =>
  productAttributeStore
    .getTopRightTagValues(props.product?.other_attributes!)
    ?.includes("gratisstarterkit")
);

const random_id = ref("");

const compareOption = ref(
  productAttributeStore.getCompareOption(props.product?.other_attributes!)
);

const unwatch = watch(() => compareStore.checkedProduts, () => {
  if (props.product) {
    isCompareChecked.value = compareStore.checkedProduts?.some(p => p.code === (props.product as Product).code);
  }
}, { immediate: true });

const handleCompareChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const productData = props.product as Product;
  if (productData) {
    const maxProducts = isMobile ? 4 : 8;
    if (target.checked) {
      if (compareStore.checkedProduts.length < maxProducts) {
        compareStore.checkedProduts.push(productData);
        compareStore.isCompareRowVisible = true;
      } else {
        toast(`Only ${maxProducts} products can be compared at a time`, { 
          autoClose: true,
          type: "error", 
        });
        target.checked = false;
      }
    } else {
      compareStore.removeCompareProduct(productData);
    }
  }
};

onMounted(() => {
  random_id.value = useRandomUUID();
});

onUnmounted(()=> unwatch());
</script>

<style>
/** {*/
/*  transition: all;*/
/*  transition-duration: 100ms;*/
/*}*/
</style>
